import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress, Typography, List, ListItem, ListItemText, Button, Grid } from '@mui/material';
import './ArtistTourDates.css'; // Import the new CSS file

function ArtistTourDates() {
  const { artistId, ticketmasterId } = useParams();
  const navigate = useNavigate();
  const [tourDates, setTourDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const TICKETMASTER_API_KEY = process.env.REACT_APP_TICKETMASTER_API_KEY; // Use environment variable

  useEffect(() => {
    const fetchTourDates = async () => {
      try {
        const response = await axios.get(`https://app.ticketmaster.com/discovery/v2/events.json?attractionId=${encodeURIComponent(ticketmasterId)}&apikey=${TICKETMASTER_API_KEY}`);

        // Log the full response for debugging
        console.log('Ticketmaster Response:', response.data);

        if (Array.isArray(response.data._embedded?.events)) {
          // Sort events by date
          const sortedEvents = response.data._embedded.events.sort((a, b) => new Date(a.dates.start.localDate) - new Date(b.dates.start.localDate));
          setTourDates(sortedEvents);
        } else {
          setTourDates([]);
        }
      } catch (error) {
        console.error('Error fetching tour dates:', error);
        setError('Failed to fetch tour dates');
      } finally {
        setLoading(false);
      }
    };

    fetchTourDates();
  }, [ticketmasterId]);

  const handleBuyTickets = (event) => {
    navigate(`/ticket-purchase/${event.id}`);
  };

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : tourDates.length === 0 ? (
        <Typography>No upcoming events found for {ticketmasterId}.</Typography>
      ) : (
        <Grid container spacing={2}>
          {tourDates.map((event) => {
            const venue = event._embedded?.venues[0] || {};
            return (
              <Grid item xs={12} sm={6} md={4} key={event.id} className="fade-in">
                <div className="tour-date-card">
                  <ListItem>
                    <ListItemText
                      primary={<strong>{event.name}</strong>}
                      secondary={
                        <>
                          <div>{new Date(event.dates.start.localDate).toLocaleDateString()}</div>
                          <div>{venue.name || 'Unknown Venue'}</div>
                          <div>
                            {venue.city?.name || 'Unknown City'}, {venue.state?.stateCode || 'Unknown State'}, {venue.country?.name || 'Unknown Country'}
                          </div>
                        </>
                      }
                    />
                    <Button variant="contained" color="primary" onClick={() => handleBuyTickets(event)}>Buy Tickets</Button>
                  </ListItem>
                </div>
              </Grid>
            );
          })}
        </Grid>
      )}
     </div>
  );
}

export default ArtistTourDates;
