import React from 'react';
import { Container, Paper, Typography, Button, Box } from '@mui/material';
import spotifyLogo from './assets/spotify-logo.svg';
import companyLogo from './assets/company-logo.png'; // Ensure the path is correct
import theme from './theme';
import './LoginPage.css'; // Import CSS for styling

function LoginPage({ onLogin }) {
    return (
        <div className="login-page">
            {/* Background animations only */}
            <div className="background-animations">
                <div className="animation-1"></div>
                <div className="animation-2"></div>
            </div>

            <Container component="main" maxWidth="xs" sx={{ mt: 8, position: 'relative', zIndex: 3 }}>
                <Paper elevation={3} sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#282828' }}>
                    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <img src={companyLogo} alt="Company Logo" className="company-logo" />
                    </Box>
                    <Typography variant="h4" sx={{ color: '#FFFFFF', textAlign: 'center', marginTop: '20px' }}>
                        Welcome to FanFidel
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#B3B3B3', textAlign: 'center', marginTop: '10px' }}>
                        Discover your top streaming artists and get tickets to see them live!
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onLogin}
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', backgroundColor: theme.palette.primary.main }}
                    >
                        <img src={spotifyLogo} alt="Spotify Logo" style={{ width: '25px', height: '25px', marginRight: 8 }} />
                        Log in with Spotify
                    </Button>
                </Paper>
            </Container>
        </div>
    );
 }

export default LoginPage;
