import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from './supabaseClient';

const CallbackPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      // Supabase will handle the OAuth state internally
      const { error } = await supabase.auth.getSessionFromUrl();
      
      if (error) {
        console.error('Error in OAuth callback:', error);
        // Redirect the user to an error page or show a message
        navigate('/login');
      } else {
        // After successful authentication, redirect the user
        navigate('/top-artists'); // Or another page
      }
    };

    handleCallback();
  }, [navigate]);

  return <div>Loading...</div>;
};

export default CallbackPage;
